/*@tailwind base;
@tailwind components;
@tailwind utilities;/**/
:root {
	--fgcolor: #444;
	--bgcolor: #fafafa;

	font-family: 'Helvetica Neue', arial, sans-serif;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	color-scheme: light dark;
	color: var(--fgcolor);
	background: var(--bgcolor);
}

@media (prefers-color-scheme: dark) {
	:root {
		--fgcolor: #fff;
		--bgcolor: #1c1c1c;
	}
}

body {
	margin: 0;
	padding: 0;
	height: 100vh;
	display: block;
	overflow: hidden;
}

#hud {
	margin-left: 20px;
	margin-top: 20px;
	max-width: 25vw;
	z-index: 10;
	position: absolute;
	left: 0;
	top: 0;
}

#hud label {
	margin: 0.4em;
}

#hud > div {
	margin: 0.3em;
}

#hud > div input {
	width: 5em;
}

#hud input[type="range"] {
	width: 16vw;
}

main {
	display: block;
	overflow: hidden;
	height: 100vh;
	width: 100vw;
	position: absolute;
	top: 0;
	left: 0;
}

@media (max-width: 639px) {
	#app > main {
		margin: 0 2rem;
	}
}

#main3Dviewport {
	display: block;
	overflow: hidden;
	height: 100vh;
}

canvas {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
	z-index:1;
}

li {
	margin: 8px;
}

.stepBtn {
	text-size-adjust: 30%;
}
/**/